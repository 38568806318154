import { Controller } from 'stimulus'

/*
 * Slider con scroll automático
 */
export default class extends Controller {
  static targets = [ 'control' ]

  connect () {
    this.active(this.controlTargets.find(x => x.href.endsWith(window.location.hash)))

    this.interval = setInterval(() => this.inViewport ? this.controlTargets[this.next].click() : null, this.duration * 1000)
  }

  get duration () {
    const duration = parseInt(this.data.get('duration'))

    return isNaN(duration) ? 15 : duration
  }

  disconnect () {
    clearInterval(this.interval)
  }

  active (control) {
    if (!control) return

    this.controlTargets.forEach(other => other.classList.toggle('active', control.href === other.href))
    this.current = this.controlTargets.indexOf(control)
  }

  activate (event) {
    // XXX: En Firefox, el target del evento también puede ser el
    // contenido del link :/
    let t = (event.target.href) ? event.target : event.target.parentElement

    this.active(t)
  }

  get current () {
    return parseInt(this.data.get('current')) || 0
  }

  set current (value) {
    this.data.set('current', value)
  }

  get next () {
    const next = this.current + 1

    return (this.controlTargets[next]) ? next : 0
  }

  get inViewport () {
    const bounding = this.element.getBoundingClientRect();

    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };
}
