import { Controller } from 'stimulus'
import { Liquid } from 'liquidjs'

/*
 * Waits for notifications and shows them by fetching and rendering
 * a template.
 */
export default class extends Controller {
  connect () {
    window.addEventListener('notification', async event => await this.render(event.detail.template, event.detail.data))
  }

  /*
   * Renders and replaces notification contents and then shows it.  Does
   * nothing if the template isn't found.
   */
  async render (name, data = {}) {
    const response = await fetch(this.template(name))

    if (!response.ok) return

    data.site = await this.site()

    const template = await response.text()
    const html = await this.engine.parseAndRender(template, data)

    this.element.innerHTML = html
    this.show()
  }

  /*
   * Gets the template path from a name
   *
   * @return [String]
   */
  template (name) {
    return this.data.get('templates') + name + '.html'
  }

  /*
   * Shows the notification
   */
  show () {
    this.element.classList.add('show')
    this.element.classList.remove('hide')
  }

  /*
   * Hides the notification
   */
  hide () {
    this.element.classList.add('hide')
    this.element.classList.remove('show')
  }

  /*
   * Liquid renderer
   *
   * @return Liquid
   */
  get engine () {
    if (!window.liquid) window.liquid = new Liquid()

    return window.liquid
  }

  /*
   * Site config (actually just translation strings)
   *
   * @return [Object]
   */
  async site () {
    if (!window.site) {
      const data = await fetch('assets/data/site.json')
      window.site = await data.json()
    }

    return window.site
  }
}
